/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');
body {
  background-color: black;
}

* {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Source Sans Pro', sans-serif;
}

.renderer {
  position: fixed;
  top: 0px;
  left: 0px;
}

.search {
  position: relative;
  text-align: center;
  z-index: 2;
}

.search>input,button {
  padding: 15px;
  border-radius: 5px;
}

#search{
  width: 150px;
}

.node-info {
  z-index: 2;
  background-color: white;
  padding: 20px 30px;
  width: 300px;
  height: 180px;
  letter-spacing: 1.5px;
  line-height: 20px;
  /* border-radius: 10px; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.7);
}

.node-info>h2 {
  text-align: center;
  margin-top: 0px;
  font-size: 30px;
}

.big-patch-info {
  z-index: 10;
  background-color: white;
  padding: 30px;
  padding-bottom: 16px;
  width: 350px;
  height: 220px;
  /* border-radius: 10px; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.row {
  position: relative;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.border {
  /* border: 1px solid red; */
  text-align: center;
}

#bigPatchNo {
  margin-top: 2px;
  font-size: small;
  font-weight: normal;
  letter-spacing: 1px;
}

#bigPatchHeading {
  margin-top: 10px;
  margin-bottom: 0px;
}

#bigPatchPrice {
  font-weight: bolder;
}

#closeBigPatchZoom {
  background-color: transparent;
  border: none;
  padding: 8px 15px;
  transition: 0.3s ease-in-out;
}

#closeBigPatchZoom:hover {
background-color: rgb(220, 220, 220);
}

#bigPatchStatus {
  font-style: bold;
}

#zoomicon {
  background-color: transparent;
  width: 14px;
  height: 12px;
  margin-left: -2px;
  margin-bottom: -2px;
  padding-right: 5px;

}


.node-info-mobile {
  z-index: 2;
  background-color: white;
  padding: 20px;
  width: 250px;
  height: 180px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.7);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 10;
}

.node-info-mobile>h2 {
  text-align: center;
  margin-top: 0px;
  font-size: 30px;
}

.labelRenderer {
  position: fixed;
  top: 0px;
  left: 0px;
}


.label3DRenderer {
  position: fixed;
  top: 0px;
  left: 0px;

}

.placeName {
  color: white;
  font-size: 12px;
}

.cross {
  background-color: transparent;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

#crossId {
  position: absolute;
  top: -8px;
  right: -10px;

}

.crossposition {
  text-align: end;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: white;
  z-index: 2;
}



.button-3 {
  appearance: none;
  background-color: #D8432A;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fabea6;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  display: none;
}

.multiSelect {
  margin-top: 8px;
  margin-left: -7px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(0, 128, 0);
  border: none;
  color: white;
}


.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #cc9990;
  color: #ff2600;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.controls {
  position: fixed;
  top: 60px;
  left: 10px;
  z-index: 4;
}

.zoom {
  width: 29px;
  height: 29px;
  margin-left: 10px;
  font-size: 32px;
  font-weight: 100;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* margin-top: 4px; */
  border-bottom: 1px solid rgba(110, 110, 110, .3);
}



.eye {
  width: 20px;
  height: 20px;
}

#show {
  margin-top: 5px;

}

.selectBox {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
}

#thumbnail {
  width: 85%;
  height: 96%;
  border-radius: 50%;
}

.canvasDiv {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
}

.button-3 {
  width: 63px;
  height: 60px;
  appearance: none;
  background-color: transparent;
  /* background-color: #D8432A; */
  /* border: 1px solid rgba(27, 31, 35, .15); */
  /* border-radius: 6px; */
  border: none;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fabea6;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  /* line-height: 20px; */
  padding: 6px 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  display: none;
  filter: invert(100%);
  transition: .3s ease;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: rgb(75, 73, 73,.1);
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}